import TextField from '@material-ui/core/TextField'
import React from 'react'

const renderMultilineTextField = ({
    rows,
    label,
    input,
    maxLength,
    meta: { touched, invalid, error },
    ...custom }) => (
        <TextField
            label={label}
            placeholder={label}
            error={touched && invalid}
            helperText={touched && error}
            {...input}
            {...custom}
            variant='outlined'
            margin='dense'
            multiline
            rows={rows ?? 4}
            inputProps={{ maxLength: maxLength ?? 500 }}
        />
    )

export default renderMultilineTextField