import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Field } from 'redux-form'
import { Container, Box } from '@material-ui/core'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import renderTextField from '../../../Forms/CustomFields/TextField'

const CustomerInfo = () => {
    return (
        <Container style={{ display: 'flex', flexDirection: 'column', maxWidth: '320px' }}>
            <Typography variant='h5'><strong>Kontaktinfo</strong></Typography>
            <Field
                component={renderTextField}
                type='text'
                name='customerName'
                label='Navn'
            />
            <Field
                style={{ width: '100%' }}
                component={renderTextField}
                type='text'
                name='phoneNumber'
                label='Mobilnummer'
            />
            <Field
                component={renderTextField}
                type='text'
                name='email'
                label='Email'
            />
            <Box>
                <ExpansionPanel style={{ marginTop: 20 }}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                            <i>
                                Om personvern
                            </i>
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography>
                            Personopplysningene du oppgir vil kun bli brukt til kommunikasjon vedrørende din service. Vi vil ikke benytte denne informasjonen til markedsføring eller distribuering. Denne informasjonen blir slettet fra vårt bookingsystem én uke etter avsluttet service. Ønsker du å få denne informasjonen slettet fra vårt kassesystem, vennligst ta kontakt med oss.
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Box>
        </Container>

    )
}

export default CustomerInfo;