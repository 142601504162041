//import { bookings, standardResources } from '../../debugData'

const API = 'productionAPI'

const SEND_BOOKING = 'SEND_BOOKING'
const BOOKING_SUCCEEDED = 'BOOKING_SUCCEEDED'
const BOOKING_FAILED = 'BOOKING_FAILED'

const CHECK_BOOKING_CODE = 'CHECK_BOOKING_CODE'
const BOOKING_CODE_SUCCEEDED = 'BOOKING_CODE_SUCCEEDED'
const BOOKING_CODE_FAILED = 'BOOKING_CODE_FAILED'

const ACCEPTED_TERMS = 'ACCEPTED_TERMS'

const LOADING_BOOKINGS = 'LOADING_BOOKINGS'
const BOOKINGS_LOADED_SUCCESS = 'BOOKINGS_LOADED_SUCCESS'
const BOOKINGS_LOADED_FAILED = 'BOOKINGS_LOADED_FAILED'

const LOADING_STANDARD_RESOURCES = 'LOADING_STANDARD_RESOURCES'
const STANDARD_RESOURCES_LOADED_SUCCESS = 'STANDARD_RESOURCES_LOADED_SUCCESS'
const STANDARD_RESOURCES_LOADED_FAILED = 'STANDARD_RESOURCES_LOADED_FAILED'

// Sending bookings
const sendBooking = booking => ({
    type: SEND_BOOKING,
    payload: { booking },
})
const bookingSucceeded = () => ({
    type: BOOKING_SUCCEEDED,
})
const bookingFailed = (error) => ({
    type: BOOKING_FAILED,
    payload: { error },
})

// Checking booking codes
const checkingBookingCode = () => ({
    type: CHECK_BOOKING_CODE,
})
const bookingCodeSucceeded = () => ({
    type: BOOKING_CODE_SUCCEEDED,
})
const bookingCodeFailed = (error) => ({
    type: BOOKING_CODE_FAILED,
    payload: { error }
})

// Accepted terms
const acceptedTerms = (acceptedTerms) => ({
    type: ACCEPTED_TERMS,
    payload: { acceptedTerms }
})

// Loading bookings
const loadBookings = () => ({
    type: LOADING_BOOKINGS,
    payload: { loadingBookings: true }

})
const bookingsLoaded = (bookings) => ({
    type: BOOKINGS_LOADED_SUCCESS,
    payload: { bookings, loadingBookings: false }
})
const loadBookingsFailed = (error) => ({
    type: BOOKINGS_LOADED_FAILED,
    payload: { error }
})

// Loading standard resources
const loadStandardResources = () => ({
    type: LOADING_STANDARD_RESOURCES,
    payload: { loadingStandardResources: true }
})
const standardResourcesLoaded = (standardResources) => ({
    type: STANDARD_RESOURCES_LOADED_SUCCESS,
    payload: { standardResources }
})
const loadStandardResourcesFailed = (error) => ({
    type: STANDARD_RESOURCES_LOADED_FAILED,
    payload: { error }
})

export const setAcceptedTerms = (acceptTerms) => dispatch => {
    dispatch(acceptedTerms(acceptTerms))
}

export const addBookingCode = code => dispatch => {
    dispatch(checkingBookingCode())
    // Send request to backend, check code
    let x = 1
    if (x === 1) {
        dispatch(bookingCodeSucceeded())
    } else {
        dispatch(bookingCodeFailed('Invalid code'))
    }
}

export const getBookings = () => async dispatch => {
    dispatch(loadBookings)

    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    //headers.append('Access-Control-Allow-Origin', 'http://localhost:3001');

    const options = {
        method: 'GET',
        headers,
    }

    // Lagre denne et annet sted? .Env fil?
    const url = `https://europe-west3-booking-calendar-63d57.cloudfunctions.net/${API}/capacity/capacity`

    try {
        const response = await fetch(url, options)
        const data = await response.json()

        if (response.status === 200) {
            dispatch(bookingsLoaded(data.result)) // data
        } else {
            dispatch(loadBookingsFailed(response.error.message)) // error.message
        }
    } catch (error) {
        //dispatch(bookingsLoaded(bookings))
        dispatch(loadBookingsFailed(error.message))
    }
}

export const getStandardResources = () => async dispatch => {
    dispatch(loadStandardResources)

    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    //headers.append('Access-Control-Allow-Origin', 'http://localhost:3001');

    const options = {
        method: 'GET',
        headers,
    }

    const url = `https://europe-west3-booking-calendar-63d57.cloudfunctions.net/${API}/capacity/standard`

    try {
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.status === 200) {
            dispatch(standardResourcesLoaded(data)) // data
        } else {
            dispatch(loadStandardResourcesFailed(response.error.message)) // error.message
        }
    } catch (error) {
        //dispatch(standardResourcesLoaded(standardResources)) // mock data
        dispatch(loadStandardResourcesFailed(error.message))
    }
}

// Takes in values in the form of an object: {formValues: {}, dateObject: Date}
export const createBooking = values => async (dispatch) => {
    dispatch(sendBooking())

    let body = {
        deliveryDate: values.date,
        orderDate: new Date(),
        customerName: values.formValues.customerName,
        customerEmail: values.formValues.email,
        customerPhone: values.formValues.phoneNumber,
        orderComment: values.formValues.comment,
    }

    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    const options = {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
    }

    const url = `https://europe-west3-booking-calendar-63d57.cloudfunctions.net/${API}/bookings/web-booking`

    try {
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.status === 200) {
            dispatch(bookingSucceeded(data)) // data
        } else {
            dispatch(bookingFailed(response.error)) // error.message
        }
    } catch (error) {
        console.log(error)
        dispatch(bookingFailed(error.message))
    }
}

const initialState = {
    sendingBooking: false,
    bookingConfirmed: false,
    errorMessage: null,
    resources: {
        standardResources: null,
        bookings: null,
    },
}

export default function bookingReducer(state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case SEND_BOOKING:
            return {
                ...state,
                bookingInfo: payload.bookingInfo,
                sendingBooking: true,
            }
        case BOOKING_SUCCEEDED:
            return {
                ...state,
                sendingBooking: false,
                bookingConfirmed: true,
            }
        case BOOKING_FAILED:
            return {
                ...state,
                sendingBooking: false,
                errorMessage: payload.error,
                bookingConfirmed: false,
            }
        case BOOKING_CODE_SUCCEEDED:
            return {
                ...state,
                bookingCode: true,
                errorMessage: null,
            }
        case BOOKING_CODE_FAILED:
            return {
                ...state,
                errorMessage: payload.error,
            }
        case ACCEPTED_TERMS:
            return {
                ...state,
                bookingInfo: {
                    acceptedTerms: payload.acceptedTerms,
                }
            }
        case LOADING_BOOKINGS: {
            return {
                ...state,
                resources: {
                    ...state.resources,
                    loadingBookings: true,
                }
            }
        }
        case BOOKINGS_LOADED_SUCCESS: {
            return {
                ...state,
                resources: {
                    ...state.resources,
                    bookings: payload.bookings,
                    loadingBookings: false,
                    bookingConfirmed: false,
                }
            }
        }
        case BOOKINGS_LOADED_FAILED: {
            return {
                ...state,
                errorMessage: payload.error
            }
        }
        case LOADING_STANDARD_RESOURCES: {
            return {
                ...state,
                resources: {
                    ...state.resources,
                    loadingStandardResources: true,
                }
            }
        }
        case STANDARD_RESOURCES_LOADED_SUCCESS: {
            return {
                ...state,
                resources: {
                    ...state.resources,
                    loadingStandardResources: false,
                    standardResources: payload.standardResources,
                }
            }
        }
        case STANDARD_RESOURCES_LOADED_FAILED: {
            return {
                ...state,
                errorMessage: payload.error
            }
        }
        default:
            return state
    }
}