import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Field } from 'redux-form'
import Button from '@material-ui/core/Button'
import { Paper, Grid, CircularProgress, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle'

import renderCheckbox from '../../../Forms/Checkbox/Checkbox'
import CalendarContainer from '../../CalendarContainer/CalendarContainer'

const ChooseDate = (props) => {
    const { resources, errorMessage } = useSelector(state => state.bookingReducer)

    return (
        <>
            {errorMessage ?
                <Alert
                    severity="error"
                    style={{ width: '100%' }}
                    action={
                        <Button variant='contained' color='primary' href='tel:55271450'>Ring for å avtale time</Button>
                    }
                >
                    <AlertTitle>
                        <strong>
                            Vår bookingkalender er dessverre nede for telling.
                            </strong>
                    </AlertTitle>
                    <Typography variant='body2'>
                        Vi jobber med saken, men i mellomtiden kan du ringe oss eller sende en mail: <a style={{ color: '#12449e' }} href='mailto:post@trekbergen.no'>post@trekbergen.no</a>
                    </Typography>
                </Alert>
                :
                <>
                    {/* <Typography variant='h4'>{props.title}</Typography> */}
                    <Grid container spacing={2} justify='center'>
                        <>
                            <Grid item lg={7} md={8} sm={12} style={{ minWidth: '50%' }}>
                                <>
                                    {(resources.bookings && resources.standardResources) ?
                                        <CalendarContainer />
                                        :
                                        <>
                                            <Box style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                backgroundColor: '#f9f9f9',
                                                borderRadius: 10,
                                                height: '100%',
                                            }}>
                                                <CircularProgress />
                                                <Typography style={{ marginTop: 10 }} variant='subtitle1'>Henter kalender...</Typography>
                                            </Box>
                                        </>
                                    }
                                </>
                            </Grid>
                            <Grid item lg={5} md={4} sm={12}>
                                <Paper style={{ minHeight: '50%', padding: 20, textAlign: 'left', backgroundColor: '#f9f9f9' }}>
                                    <Typography variant='h5' style={{ textAlign: 'left' }}>
                                        <strong>
                                            Betingelser om levering
                                    </strong>
                                    </Typography>
                                    <br />
                                    <Typography variant='body1' style={{ textAlign: 'left' }}>
                                        Sykkelen må leveres inn dagen før verkstedstimen, eller senest kl. 11 samme dag.
                                    </Typography>
                                    <br />
                                    <Typography variant='body1' style={{ textAlign: 'left' }}>
                                        Prisoverslag gis kun ved levering av sykkelen. Dette overslaget er ikke forpliktende,
                                        og du kan selv velge om du vil gjennomføre servicen. Prisoverslag vil ikke bli gitt over telefon.
                                    </Typography>
                                    <br />
                                    <Typography variant='body1' style={{ textAlign: 'left' }}>
                                        Verkstedet vårt reparerer ikke visse sykler - <a href='https://trekbergen.no/hjem/verksted/' target='_blank'>du kan lese mer om dette her.</a>
                                    </Typography>
                                    <br />
                                    <Box style={{ fontSize: 20 }}>
                                        <Typography variant='body1'>
                                            Sykler som ikke avhentes innen to måneder etter avtalt tid blir kondemnert.
                                        </Typography>
                                        <Field
                                            type='input'
                                            component={renderCheckbox}
                                            label='Jeg godtar disse betingelsene'
                                            name='acceptedTerms'
                                            color='primary'
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                        </>
                    </Grid>
                </>
            }
        </>
    )
}

export default ChooseDate