import TextField from '@material-ui/core/TextField'
import React from 'react'

const renderTextField = ({
    label,
    input,
    showLabel,
    meta: { touched, invalid, error },
    ...custom }) => (
        <TextField
            id={input.name}
            placeholder={label}
            error={touched && invalid}
            helperText={touched && error}
            {...input}
            {...custom}
            variant='outlined'
            margin='dense'
            label={showLabel ? label : null}
        />
    )

export default renderTextField