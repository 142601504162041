import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#12449e',
        },
        secondary: {
            main: '#1dd12b',
        }
    },
    status: {
        danger: 'orange',
        success: {
            main: 'green',
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
})

export default theme