import React from 'react';
import { ThemeProvider, Container } from '@material-ui/core';
import theme from './theme'

import BookingStepper from './Components/NewBookingCalendar/Stepper/BookingStepper'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container >
        <BookingStepper />
      </Container>
    </ThemeProvider>

  );
}

export default App;
