import React from 'react'
import { useSelector } from 'react-redux';
import { Grid, CircularProgress, Typography, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import Rating from 'material-ui-rating'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle'
import logo from '../../../../static/trek-bergen-logo.png'

const BookingConfirmed = (props) => {
    const classes = styles()
    const { sendingBooking, bookingConfirmed, errorMessage } = useSelector(state => state.bookingReducer)
    const [rating, setRating] = React.useState()

    return (
        <Box className={classes.root}>
            {sendingBooking && !bookingConfirmed ?
                <Box className={classes.loadingBox}>
                    <CircularProgress />
                    <Typography variant='h6' style={{ marginTop: 20 }}>Behandler booking...</Typography>
                </Box >
                :
                <>
                    {errorMessage ?
                        <Alert
                            severity="error"
                            action={
                                <Button variant='contained' color='primary' href='tel:55271450'>Ring oss</Button>
                            }
                            className={classes.alert}
                        >
                            <AlertTitle>
                                <strong>
                                    Vi kunne dessverre ikke bekrefte din booking
                                </strong>
                            </AlertTitle>
                            <Typography variant='body2'>
                                Vi jobber med saken, men i mellomtiden kan du ringe oss eller sende en mail: <a style={{ color: '#12449e' }} href='mailto:post@trekbergen.no'>post@trekbergen.no</a>
                            </Typography>
                        </Alert>
                        :
                        <>
                            <div className={classes.headerContainer}>
                                <CheckCircleIcon fontSize='large' className={classes.checkIcon} />
                                <Typography variant='h5'>Din booking er mottatt!</Typography>
                            </div>

                            <Typography variant='body1' style={{ marginTop: 10 }}>
                                Vi har sendt deg en e-post med en bekreftelse på bestilling. Husk å levere sykkelen dagen før, eller senest kl. 11 samme dag.
                            </Typography>
                            <Grid container style={{ marginTop: 40 }} spacing={3} justify='space-between'>
                                <Grid item md={6} sm={12}>
                                    <Typography variant='subtitle1'>
                                        <strong>
                                            Med vennlig hilsen,
                                        </strong>
                                    </Typography>
                                    <img alt='Trek Bergen' src={logo} className={classes.logo} />
                                </Grid>
                                <Grid item md={6} sm={12} style={{ textAlign: 'center', width: '100%' }}>
                                    <Typography variant='subtitle2'>
                                        Hva synes du om bookingen vår?
                                    </Typography>
                                    <Rating
                                        max={5}
                                        value={rating}
                                        onChange={(value) => value === rating ? setRating(0) : setRating(value)}
                                    />
                                </Grid>
                            </Grid>
                            <Box>
                                <Button variant='contained' color='primary' className={classes.linkButton}>
                                    <a href='http://trekbergen.no' style={{ textDecoration: 'none', color: 'white' }}>Tilbake til Trek Bergen</a>
                                </Button>
                            </Box>
                        </>
                    }
                </>
            }
        </Box>
    )
}

const styles = makeStyles(theme => ({
    root: {
        padding: 50,
        height: '80%',
        [theme.breakpoints.down('md')]: {
            padding: 0,
            height: '100%',
            textAlign: 'center'
        }
    },
    logo: {
        maxWidth: '80%',
        [theme.breakpoints.down('md')]: {
            maxWidth: '35%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '50%',
        }
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            marginTop: 100,
            justifyContent: 'center',
        },
    },
    checkIcon: {
        color: '#0db50d',
        marginRight: 5,
    },
    loadingBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    alert: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        }
    },
    linkButton: {
        textDecoration: 'none',
        color: 'white',
        [theme.breakpoints.down('md')]: {
            marginTop: 20,
        },
    }
}))

export default BookingConfirmed;