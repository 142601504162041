import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { FormControlLabel } from '@material-ui/core'

const renderCheckbox = ({
    label,
    input,
    color,
}) => (
        <FormControlLabel
            label={label}
            control={<Checkbox color={color} checked={input.value ? true : false} onChange={input.onChange} />}
        />
    )

export default renderCheckbox