import { getDaysInMonth, getMonth, getDay, eachDayOfInterval } from 'date-fns'

// Bookings is array this months bookings, {date, count}
// Standard resources is array of days in the week, starting on monday = 2, sunday = 1: {capacityCount: number, weekdayID: number}
// month is numeric month (9 is october, 0 is january)
export const calculateResources = (bookings, standardResources, month, year) => {
    let resources = []
    let today = new Date()
    let isThisMonth = month === getMonth(today) // Checks if current month is this month
    let daysInMonth = getDaysInMonth(new Date(year, month)) // Number of days in this month
    let daysOfMonth = eachDayOfInterval({ start: new Date(`${month + 1}/01/${year}`), end: new Date(`${month + 1}/${daysInMonth}/${year}`) })
    for (let i = 1; i <= daysInMonth; i++) {
        const day = i
        let tempDate = daysOfMonth[day - 1]
        let dayOfWeek = getDay(tempDate) // monday is 1

        // Shifting dayOfWeek by -1 - monday is now 0 (prevoiously sunday was 0)
        dayOfWeek--
        if (dayOfWeek < 0) dayOfWeek = 6

        // Finds booking if there is one on this day
        let booking = bookings.find(b => {
            let bookingDate = new Date(b.date)
            let bookingDay = bookingDate.getDate() // Finds day of month, starting at 1 --- end of month
            return day === bookingDay
        })

        if (isThisMonth) {
            // Preventing from bookings past today
            if (i > today.getDate()) {
                if (booking) {
                    resources.push(booking.available)
                } else {
                    resources.push(standardResources[dayOfWeek].capacityCount)
                }
            } else {
                resources.push(0) // All dates in the past
            }
        } else {
            if (booking) {
                resources.push(booking.available)
            } else {
                resources.push(standardResources[dayOfWeek].capacityCount)
            }
        }
    }
    return resources
}

// list is array of bookings {date: Date, available: number}
export const sortMonth = (list, currentMonth) => {
    let result = list.filter(b => {
        return getMonth(new Date(b.date)) === currentMonth
    })
    return result
}

// Calculates the maximum month that is available based on a count of months that are allowed.
// E.g. if maxAllowed = 4, it will return the month number 4 months away, regardless of new year.
// If thisMonth = 10 (november), then it returns 1 (february) which is four months from november.
export const getMaxMonthAvailable = maxAllowed => {
    const thisMonth = getMonth(new Date())
    let maxMonth = ((thisMonth + maxAllowed) % 12) - 1
    if (maxMonth < 0) return 11
    return maxMonth
}