import { getYear, getMonth } from 'date-fns'
import { getMaxMonthAvailable } from '../../utils/utils'

const SELECT_DATE = 'SELECT_DATE'
const CLEAR_SELECTED_DATE = 'CLEAR_SELECTED_DATE'

const SET_MONTH = 'SET_MONTH'
const SET_YEAR = 'SET_YEAR'

const selectDate = date => ({
    type: SELECT_DATE,
    payload: { selectedDate: date }
})

const resetDate = () => ({
    type: CLEAR_SELECTED_DATE,
})

const setMonth = (month) => ({
    type: SET_MONTH,
    payload: { month }
})

const setYear = (year) => ({
    type: SET_YEAR,
    payload: { year }
})

export const setSelectedMonth = (newMonth) => (dispatch, getState) => {
    const { year } = getState().calendarReducer

    // Set right month
    if (newMonth === 12) {
        dispatch(setMonth(0))
        dispatch(setYear(year + 1))
    } else if (newMonth === -1) {
        dispatch(setMonth(11))
        dispatch(setYear(year - 1))
    } else {
        dispatch(setMonth(newMonth))
    }
}

export const setSelectedDate = date => dispatch => {
    dispatch(selectDate(date))
}

export const clearSelectedDate = () => dispatch => {
    dispatch(resetDate())
}

const initialState = {
    selectedDate: null,
    month: getMonth(new Date()),
    year: getYear(new Date()),
    maxMonthAvailable: 3,
    nextMonthDisabled: false,
}

export default function calendarReducer(state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case SELECT_DATE:
            return {
                ...state,
                selectedDate: payload.selectedDate,
            }
        case CLEAR_SELECTED_DATE:
            return {
                ...state,
                selectedDate: null,
            }
        case SET_MONTH:
            const nextMonthAvailable = getMaxMonthAvailable(state.maxMonthAvailable)
            const nextMonthDisabled = nextMonthAvailable === payload.month
            return {
                ...state,
                month: payload.month,
                nextMonthDisabled,
            }
        case SET_YEAR:
            return {
                ...state,
                year: payload.year,
            }
        default:
            return state
    }
}