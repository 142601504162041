import React from 'react';
import { useSelector } from 'react-redux';
import { TableRow, TableCell, TableHead, TableBody, Table, makeStyles } from '@material-ui/core';
import { getDay, getDaysInMonth, startOfMonth } from 'date-fns'
import CalendarBlob from './CalendarBlob/CalendarBlob';

const CalendarTable = (props) => {
    const classes = styles()
    const year = useSelector(state => state.calendarReducer.year)
    const month = useSelector(state => state.calendarReducer.month)

    // Number of days in month
    const daysInMonth = getDaysInMonth(new Date(year, month))

    // First day of the month
    const firstDay = () => {
        let c = getDay(startOfMonth(new Date(year, month)))
        if (c === 0) c = 7
        return c
    }

    // Array of JS date objects
    let date = new Date(year, month, 1);
    let dates = [];
    while (date.getMonth() === month) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    // Blank slots
    let blanks = [];
    for (let i = 1; i < firstDay(); i++) {
        blanks.push(
            <TableCell key={i + '_blank'} style={{ border: 'none', textAlign: 'center', padding: 8 }}>
                <CalendarBlob
                    style={{ border: 'none' }}
                    resource={0}
                    date={null}
                />
            </TableCell>
        )
    }

    // Filled slots
    let days = []
    for (let i = 1; i <= daysInMonth; i++) {
        days.push(
            <TableCell
                key={i + '_day'}
                style={{ border: 'none', textAlign: 'center', padding: 8 }}
            >
                <CalendarBlob
                    date={dates[i - 1]}
                    resource={props.resources[i - 1]}
                    sizeClass={classes.dateCellSize}
                />
            </TableCell>
        )
    }

    // Merging blank and filled slots
    let arr = [...blanks, ...days]

    let cells = [] // Each cell in a week, gets pushed into rows
    let row = [] // All the rows of a month (or weeks)
    let dayNumber = 1 // Day of week, gets resetted to 0 when reaching 8
    for (let i = 1; i <= arr.length; i++, dayNumber++) {
        if (dayNumber % 8 === 0) { // If we have reached the end of the week, push the cells to a row
            row.push(
                <TableRow key={'row_' + i + '_' + month} md={12}>
                    {cells}
                </TableRow>
            )
            dayNumber = 0
            cells = [] // Resetting cells
            i-- // Making sure we dont skip a day
        } else { // Push item in array to cell
            cells.push(
                arr[i - 1]
            )
        }
        if (i === arr.length) { // If we have reached the end of the array, push the remaining cells
            row.push(
                <TableRow key={'row_' + i}>
                    {cells}
                </TableRow >
            )
        }
    }

    let weekdays = ['ma.', 'ti.', 'on.', 'to.', 'fr.', 'lø.', 'sø.']

    // Weekdays in header
    let weekdaysName = weekdays.map(day => {
        return (
            <TableCell style={{ border: 'none', textAlign: 'center' }} key={day}>
                {day}
            </TableCell>
        )
    })

    return (
        <Table className={classes.root} padding={'checkbox'}>
            <TableHead>
                <TableRow>
                    {weekdaysName}
                </TableRow>
            </TableHead>
            <TableBody>
                {row}
            </TableBody>
        </Table >
    )
}

const styles = makeStyles(theme => ({
    root: {
        color: 'red',
    },
    dateCellSize: {
        width: 35,
        height: 35,
        [theme.breakpoints.up('md')]: {
            width: 60,
            height: 45,
        }
    }
}))

export default CalendarTable;