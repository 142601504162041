import React, { useEffect } from 'react'
import MobileStepper from '@material-ui/core/MobileStepper';
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { reduxForm } from 'redux-form'
import MediaQuery from 'react-responsive'

import { bookingValidator } from "../../../utils/validator";
import { Paper } from '@material-ui/core'
import ChooseDate from "./Steps/ChooseDate";
import CustomerInfo from './Steps/CustomerInfo'
//import BikeInfo from './Steps/BikeInfo'
import BookingConfirmed from "./Steps/BookingConfirmed";
import ConfirmOrder from './Steps/ConfirmOrder'
import { useSelector, useDispatch } from 'react-redux'
import theme from '../../../theme'
import { getBookings, getStandardResources } from "../../../state/ducks/booking";
import BikeInfo from './Steps/BikeInfo';

let BookingStepper = props => {
    const dispatch = useDispatch()
    const classes = styles()
    const [activeStep, setActiveStep] = React.useState(0) // integer, starting at 0, ending at 4
    const formValues = useSelector(state => state.form.bookingForm.values)
    const bookingDate = useSelector(state => state.calendarReducer.selectedDate)
    const { handleSubmit } = props

    // Handles moving on to the next step
    // Only moves on if required fields are provided
    const handleNext = () => {
        if (true) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
        else {
            // Required fields are not provided
        }
    }

    // Gets content of step
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <ChooseDate title={getSteps()[step]} />
                )
            case 1:
                return (
                    <CustomerInfo title={getSteps()[step]} />
                )
            case 2:
                return (
                    <BikeInfo />
                )
            case 3:
                return (
                    <ConfirmOrder handleSubmit={handleSubmit} handleNext={handleNext} title={getSteps()[step]} />
                )
            case 4:
                return <BookingConfirmed title={getSteps()[step]} />
            default:
                return <p>Step default</p>
        }
    }

    let activeStepContent = getStepContent(activeStep)
    const steps = getSteps()

    // Handles going back to the previous step
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    // Creates all step elements
    let stepHeaderContent = steps.map((label, index) => {
        const stepProps = {}
        const labelProps = {}
        return (
            <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
        )
    })

    let disableNextStep = true
    if (activeStep === 0 && formValues) disableNextStep = bookingDate === null || !formValues.acceptedTerms
    if (activeStep === 1 && formValues) disableNextStep = !formValues.customerName || !formValues.phoneNumber || !formValues.email
    if (activeStep === 2) disableNextStep = false

    useEffect(() => {
        dispatch(getBookings())
        dispatch(getStandardResources())
    }, [])

    return (
        <Paper className={classes.root}>
            <div>
                <MediaQuery maxDeviceWidth={theme.breakpoints.values.md}>
                    <MobileStepper
                        activeStep={activeStep}
                        variant='text'
                        steps={getSteps().length}
                        position='bottom'
                        style={{ paddingBottom: 20 }}
                        nextButton={
                            <Button
                                variant="contained"
                                color='primary'
                                onClick={handleNext}
                                className={`${classes.button}`}
                                disabled={disableNextStep}
                            >
                                Neste
                        </Button>}
                        backButton={<Button disabled={activeStep === 0 || activeStep === 4} onClick={handleBack} className={classes.button}>
                            Tilbake
                                </Button>}
                    >
                    </MobileStepper>
                </MediaQuery>
                <MediaQuery minDeviceWidth={theme.breakpoints.values.md}>
                    <Stepper activeStep={activeStep}>
                        {stepHeaderContent}
                    </Stepper>
                </MediaQuery>

                <form>
                    <div className={classes.contentContainer}>
                        {activeStepContent}
                    </div>
                </form>
            </div>
            <MediaQuery minDeviceWidth={theme.breakpoints.values.md}>
                <div className={classes.navigationButtonsContainer}>
                    {activeStep < 4 &&
                        <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                            Tilbake
                                    </Button>
                    }
                    {activeStep < 3 &&
                        <Button
                            variant="contained"
                            color='primary'
                            onClick={handleNext}
                            className={`${classes.button}`}
                            disabled={disableNextStep}
                        >
                            {activeStep === steps.length - 2 ? 'Bestill' : 'Neste'}
                        </Button>
                    }
                </div>
            </MediaQuery>

        </Paper >
    )

}

// Gets step titles
function getSteps() {
    return ['Velg dato', 'Kontaktinfo', 'Tilleggsopplysninger', 'Bekreft ordre', 'Ferdig']
}

const styles = makeStyles(theme => ({
    root: {
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            padding: 10,
            margin: '30px 20px',
        },
        [theme.breakpoints.down('sm')]: {
            boxShadow: 'none'

        },
    },
    button: {
        marginRight: 20,
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
        },
    },
    hideButton: {
        display: 'none',
    },
    instructions: {
        marginTop: 20,
    },
    navigationButtonsContainer: {
        marginTop: 30,
        display: 'flex',
        justifyContent: 'space-between',
    },
    contentContainer: {
        padding: '0 100px',
        marginTop: 20,
        minHeight: '20vh',
        [theme.breakpoints.down('md')]: {
            padding: 0,
            marginBottom: 50,
        },
    },
}))

BookingStepper = reduxForm({
    form: 'bookingForm',
    validate: bookingValidator,
})(BookingStepper)

export default BookingStepper