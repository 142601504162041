import React from 'react'
import { Typography, Box, Container } from '@material-ui/core'
import { Field } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'

import renderMultilineTextField from "../../../Forms/CustomFields/MultilineTextField";

const BikeInfo = () => {
    const classes = styles()

    return (
        <Container className={classes.root}>
            <Typography variant='h5'><strong>Hva skal gjøres? (valgfritt)</strong></Typography>
            <Box className={classes.commentFieldContainer}>
                <Field
                    component={renderMultilineTextField}
                    type='text'
                    name='comment'
                    rows={8}
                    maxLength={300}
                    style={{ width: '100%' }}
                />
            </Box>
        </Container>
    )
}

const styles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 800,
    },
    commentFieldContainer: {
        [theme.breakpoints.up('md')]: {
        },
    }
}))

export default BikeInfo;