import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import CalendarTable from '../CalendarTable'
import CalendarPicker from '../CalendarPicker';
import { calculateResources, sortMonth } from '../../../utils/utils'

const CalendarContainer = props => {
    const { month, year } = useSelector(state => state.calendarReducer)
    const rs = useSelector(state => state.bookingReducer.resources)
    const [resources, setResources] = useState([])

    useEffect(() => {
        let thisMonth = sortMonth(rs.bookings, month)

        // Array of all resources   
        let rss = calculateResources(thisMonth, rs.standardResources, month, year)
        setResources(rss)
    }, [month])

    return (
        <div>
            {resources.length &&
                <>
                    <CalendarPicker />
                    <CalendarTable resources={resources} />
                </>
            }
        </div>

    )
}

export default CalendarContainer
