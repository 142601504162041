import React from 'react';
import { Grid, Table, TableBody, TableRow, TableCell, Typography, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'
import ReCAPTCHA from "react-google-recaptcha";

import { createBooking } from '../../../../state/ducks/booking'
import { format } from 'date-fns';
import { nb } from 'date-fns/locale'

const ConfirmOrder = props => {
    const [isRobot, setIsRobot] = React.useState(true)
    const dispatch = useDispatch()
    const formValues = useSelector(state => state.form.bookingForm.values)
    const bookingDate = useSelector(state => state.calendarReducer.selectedDate)
    const classes = useStyles()

    const handleSendBooking = values => {
        dispatch(createBooking({ formValues: values, date: bookingDate }))
        props.handleNext()
    }

    return (
        <>
            {/* <Typography variant='h4'><strong>{props.title}</strong></Typography> */}
            <Grid container justify='center' spacing={4} >
                <Grid name='customerInfo' className={classes.infoBox} item md={6} sm={12}>
                    <Typography variant='h5'><strong>Kontaktinfo</strong></Typography>
                    <Table className={classes.orderDetailsTable}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant='body1'>Navn:</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='body1'>{formValues.customerName ?? '---'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant='body1'>Mobilnummer:</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='body1'>{formValues.phoneNumber ?? '---'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant='body1'>Email:</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='body1'>{formValues.email ?? '---'}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid name='bookingInfo' className={classes.infoBox} item md={6} sm={12}>
                    <Typography variant='h5'><strong>Bookinginfo</strong></Typography>
                    <Table className={classes.orderDetailsTable}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant='body1'>Dato:</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='body1'>{format(new Date(bookingDate), 'do MMMM yyyy', { locale: nb })}</Typography>
                                </TableCell>
                            </TableRow>
                            {formValues.comment &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='body1'>Dette skal gjøres:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='body1'>{formValues.comment}</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid container justify='center' spacing={4} >
                <Grid item md={6} sm={12} className={classes.infoBox}>
                    <ReCAPTCHA
                        sitekey='6LcO66IZAAAAAL8cUZL665dwu5zwMbaekozSQyxe'
                        onChange={() => setIsRobot(false)}
                    />
                </Grid>
                <Grid item md={6} sm={12} className={classes.infoBox}>
                    <Button
                        disabled={isRobot}
                        onClick={props.handleSubmit(handleSendBooking)}
                        variant='contained'
                        className={classes.submitButton}>Bestill</Button>
                </Grid>
            </Grid>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    infoBox: {
        textAlign: 'left',
        padding: 10,
        maxWidth: '400px',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    submitButton: {
        backgroundColor: '#1dd12b',
        color: 'white',
        fontWeight: 600,
        fontSize: 16,
        padding: '10px 20px',
        width: 200,
        height: 50,
        '&:hover': {
            backgroundColor: '#3fd94b'
        }
    },
    orderDetailsTable: {
        border: '1px solid #e2e2e2'
    },
}))

export default ConfirmOrder;