import React from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip';
import fecha from 'fecha'

import theme from '../../../theme'
import { clearSelectedDate, setSelectedDate } from "../../../state/ducks/calendar"
import { useDispatch, useSelector } from 'react-redux'

const CalendarBlob = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const selectedDate = useSelector(state => state.calendarReducer.selectedDate)

    let date = null
    if (props.date) {
        date = props.date.getDate() ?? null
    }

    const handleOnClick = () => {
        if (isSelected) {
            dispatch(clearSelectedDate())
        }
        else {
            dispatch(setSelectedDate(fecha.format(props.date, 'YYYY-MM-DD')))
        }
    }

    // Conditional CSS-classes
    const today = false ? classes.today : ''
    const todaysDate = props.date ? fecha.format(props.date, 'YYYY-MM-DD') : null
    let isSelected
    if (props.date && selectedDate) {
        isSelected = selectedDate === todaysDate ? classes.isSelected : ''
    }
    const noneAvailable = props.resource === 0
    const disabled = noneAvailable ? classes.disabled : ''
    return (
        <Tooltip title={'Ledige plasser: ' + props.resource} arrow>
            <span style={{ display: 'block' }}>
                <ButtonBase
                    focusRipple
                    className={`${classes.root} ${props.sizeClass} ${today} ${isSelected} ${disabled}`}
                    onClick={handleOnClick}
                    disabled={noneAvailable}
                >
                    <div className={classes.blob}>
                        <p>{date}</p>
                    </div>
                </ButtonBase>
            </span>
        </Tooltip>
    )
}
const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        borderRadius: 30,
        fontSize: 16,
        backgroundColor: theme.palette.primary.light,
        color: 'white',
        transition: 'all 0.3s ease-out',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            cursor: 'pointer',
        },
    },
    today: {
        border: '1px solid red',
    },
    isSelected: {
        //border: `2px solid ${theme.palette.secondary.main}`,
        backgroundColor: '#0fcc37',
        color: 'white',
        '&:hover': {
            backgroundColor: '#1bec48',
        }
    },
    disabled: {
        backgroundColor: '#f3f3f3',
        color: '#909090',
        textDecoration: 'line-through',
    },
    blob: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default CalendarBlob;