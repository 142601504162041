import React from 'react'
import { Typography, Button, makeStyles } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useDispatch, useSelector } from 'react-redux'

import { setSelectedMonth } from "../../state/ducks/calendar";
import { format, getMonth } from 'date-fns'
import { nb } from 'date-fns/locale'

const CalendarPicker = () => {
    const classes = styles()
    const dispatch = useDispatch()
    const { month, year, nextMonthDisabled } = useSelector(state => state.calendarReducer)

    // only for UI
    let m = format(new Date(year, month), 'MMMM', { locale: nb })

    let isThisMonth = month === getMonth(new Date())

    const handleNextMonth = () => {
        let newMonth = month + 1
        dispatch(setSelectedMonth(newMonth))
    }

    const handlePreviousMonth = () => {
        let newMonth = month - 1
        dispatch(setSelectedMonth(newMonth))
    }
    return (
        <div className={classes.root}>
            <Button disabled={isThisMonth} onClick={handlePreviousMonth} ><ArrowBackIcon /></Button>
            <Typography style={{ textTransform: 'capitalize' }} variant='h4'>{m}</Typography>
            <Button disabled={nextMonthDisabled} onClick={handleNextMonth}><ArrowForwardIcon /></Button>
        </div>
    )
}

const styles = makeStyles(theme => ({
    root: {
        display: 'flex',
        margin: 'auto',
        justifyContent: 'space-between',
        width: '100%',
    },
}))

export default CalendarPicker;