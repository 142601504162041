const text = {
    required: 'Må fylles inn',
    invalidEmail: 'Ugyldig e-mail adresse',
    invalidNumber: 'Ugyldig mobilnummer, prøv å fjerne mellomrom. For landskoder, bruk "0046" (ikke "+46")',
    invalidBookingCode: 'Ugyldig kode',
}

const checkEmail = email => {
    return !/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/i.test(email)
}

const checkPhoneNumber = number => {
    return !/^(?:\d{2}\s?){3}\d{2,9}\b$/i.test(number)
}

export const bookingValidator = values => {
    const errors = {}

    // Email
    if (!values.email) {
        errors.email = text.required
    } else if (checkEmail(values.email)) {
        errors.email = text.invalidEmail
    }

    // Name       
    if (!values['customerName']) {
        errors['customerName'] = text.required
    }

    // Phone number
    if (!values['phoneNumber']) {
        errors['phoneNumber'] = text.required
    } else if (checkPhoneNumber(values['phoneNumber'])) {
        errors['phoneNumber'] = text.invalidNumber
    }
    return errors
}