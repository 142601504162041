import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import thunk from 'redux-thunk'
import { reducer as formReducer } from 'redux-form'

import calendarReducer from './ducks/calendar'
import bookingReducer from "./ducks/booking"

const rootReducer = combineReducers({
    bookingReducer,
    calendarReducer,
    form: formReducer,
})

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(thunk),
    )
)

export default store